import React from "react"
import SEO from "../../components/seo"
import HeroFeaturePage from "../../components/pages/shared/hero/feature-page"
import Layout from "../../components/layout"
import AboutComponent from "../../components/pages/main/about"
import BannerComponent from "../../components/pages/features/banner"
import FeaturesComponent from "../../components/pages/main/landing-features"
import FeatureAccordion from "../../components/pages/main/accordion"
import ContactFormComponent from "../../components/pages/shared/contact-form"
import Discount from "../../components/pages/main/discount"
import Breadcrumb from '../../components/controls/breadcrumbs';

const analyticsAccordionData = [
  {
    name: "include",
    title: "Что есть в разделе Аналитика?",
    content: `
      <p>В разделе CRM Аналитика находятся данные по доходам, расходам, абонементам, посещениям и занятиям. А еще краткая сводка по воронке продаж.</p>
    `,
  },
  {
    name: "increase",
    title: "Как Аналитика помогает увеличивать доход?",
    content: `
      <p>Мониторинг развития и результативности направления поможет определить предпочтения вашей аудитории. Персонализация укрепит доверие действующих клиентов и привлечет потенциальных. В CRM аналитика продаж дает возможность быстро проанализировать необходимые данные для создания индивидуальных решений и приумножения прибыли.</p>
    `,
  },
  {
    name: "reports",
    title: "Какие отчеты есть в разделе Аналитика?",
    content: `
      <p>Какие отчеты есть в разделе Аналитика?</p>
      <ul>
        <li>База учеников</li>
        <li>База лидов</li>
        <li>Финансовый отчет</li>
        <li>Отчет по абонементам</li>
        <li>Отчет по посещениям</li>
        <li>Отчет по занятиям</li>
        <li>Финансовые операции: доходы и расходы</li>        
      </ul>
    `,
  }
]

const AnalyticsFeaturePage = (props) => {
  const crumbs = [
    { name: 'Главная', url: '/'},
    { name: 'Возможности', url: '/feature'},
    { name: 'Аналитика', url: ''}
  ];
  return (
    <Layout headerColor={"#6D20D2"} page={"analytics"}>
      <SEO
        title="Эффективные инструменты аналитики в CRM-системе Параплан"
        keywords={[`аналитика детского центра`, `финансовый отчет в детском центре`]}
        description="Попробуйте возможности CRM аналитики в режиме реального времени для детских центров, салонов красоты, спортивных и творческих школ."
        url={"https://paraplancrm.ru" + props.location.pathname}
      />
      <HeroFeaturePage
        name={"analytics"}
        htmlTitle='Подружитесь <br/> с аналитикой в CRM'
        description='Воспользуйтесь инструментами CRM-аналитики. Параплан поможет выявить эффективные направления и сделать шаг в сторону успеха.'
        color='#6D20D2'
        btnGreen={true}
        image={require("../../images/cover/analytics.png")}/>
      <Breadcrumb crumbs={ crumbs } arrowcolor={"purple"}/>
      <AboutComponent
        title='Узнайте, какое направление работает эффективно'
        description='Раздел Аналитика в CRM-системе добавит вашим данным наглядность и даст необходимую информацию для корректировки работы в действующих группах. Отслеживание роста и эффективности направления поможет выявить предпочтения вашей аудитории.'
        image={require("../../images/screenshot/analytics/analytics-1.png")}
        imageWidth={671}
        imageHeight={356}
        reverse={true}
        color='#E65D49'/>
      {/*<FreeSummer topInd="100"/>*/}
      <Discount/>
      <AboutComponent
        title='Просматривайте <br/> отчеты по разным <br/> направлениям'
        description='Изучайте аналитику с помощью CRM-системы по любым параметрам, включая посещаемость, прирост клиентов, количество проданных абонементов, доходы и расходы. Мы предоставим статистику в реальном времени, чтобы вы могли быстро определить, что работает, а что требует улучшения.'
        image={require("../../images/screenshot/analytics/analytics_05@2x.png")}
        imageWidth={601}
        imageHeight={444}
        bgSize={"position-left"}
        color='#FFC800'/>
      <AboutComponent
        title='Узнайте, что нравится вашим клиентам'
        description="Наши отчеты о росте, вовлеченности и доходах помогут вам узнать больше о поведении вашей аудитории и выяснить, какой тип занятий у них наиболее популярен. Благодаря аналитике в CRM, вы можете узнать количество людей на конкретном направлении, как часто они ходили, сколько потратили и многое другое. Так вы сможете продвигать успешные направления и попадать в ожидания клиентов."
        image={require("../../images/screenshot/analytics/analytics_04@2x.png")}
        imageWidth={610}
        imageHeight={491}
        bgSize={"position-right"}
        reverse={true}
        color='#5898F7'/>
      <BannerComponent color={"green"}>
        Принимайте правильные решения.<br/>
        Параплан даст вам инструменты для анализа эффективности направлений и поможет увеличить ваш доход
      </BannerComponent>
      <FeaturesComponent title="Дополнительные возможности"/>
      <FeatureAccordion
            data={analyticsAccordionData}
            title='Воспользуйтесь аналитикой в CRM и увеличьте прибыль'
            descr='CRM-аналитика помогает оценить эффективность отдела продаж, маркетинговой стратегии и рентабельности бизнеса. Анализируя данные, вы быстро выявите сильные и слабые стороны вашего бизнеса, определите угрозы и возможности. А еще, узнаете истинные потребности своих клиентов и обеспечите индивидуальный подход. Благодаря персонализации и отличию от конкурентов, ваш бизнес будет расти, а доход приумножаться.
            Инструменты аналитики в CRM-системе позволяют создавать отчеты образовательным центрам разных направлений. Вам не нужно вручную считать количество лидов и продаж, доходы и расходы за месяц. Параплан CRM выполнит это в несколько кликов и сформирует для вас готовый отчет. Переложите на систему ещё больше рутинных задач и сэкономьте время с помощью автоматизации бизнеса.'
      />
      <ContactFormComponent/>
    </Layout>
  )
}

export default AnalyticsFeaturePage
